// FILE: global.js

// Import libraries
import throttle from 'lodash';

// From component folder
import {
    navPrimary,
    lazy,
    message,
    salJs,
    select,
    utility,
    scrollTop
} from './components/global';

class Global {

    loadGlobal() {

        // Invoke methods
        navPrimary.loadNavPrimary();
        lazy.loadLazy();
        message.loadMessage();
        salJs.loadSalJs();
        select.loadSelect();
        scrollTop.loadScrollTop();
        utility.loadUtility();

        //console.log('Global components loaded');
    }

}

export let global = new Global()

global.loadGlobal();
