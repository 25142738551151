// FILE: select.js

class Select {

    loadSelect() {

        // console.log("Select Loaded")

        // const selectField = document.getElementById('catSelector');

        // selectField.onchange = function(event){
        //     var link = event.target.options[event.target.selectedIndex].dataset.link;
        //     // console.log("link: " + link);
        //     if (link !== '') {
        //         window.location.href = link;
        //     }
        // };

    }
}

export let select = new Select();
