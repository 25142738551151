// FILE: scrollTop.js

class ScrollTop {

    loadScrollTop() {

        function trackScroll() {
            var scrolled = window.pageYOffset;
            var coords = document.documentElement.clientHeight;

            if (scrolled > coords) {
                goTopBtn.classList.add('mod--show');
            }
            if (scrolled < coords) {
                goTopBtn.classList.remove('mod--show');
            }
        }

        function backToTop() {
            if (window.pageYOffset > 0) {
                window.scrollBy(0, -80);
                setTimeout(backToTop, 0);
            }
        }

        var goTopBtn = document.querySelector('.button__scrollTop');

        window.addEventListener('scroll', trackScroll);
        goTopBtn.addEventListener('click', backToTop);

        // console.log("ScrollTop module loaded")

    }
}

export let scrollTop = new ScrollTop();