// FILE: navPrimary.js

class NavPrimary {

    loadNavPrimary() {

        const body = document.body
        const navigationHeight = document.getElementsByClassName('headerPrimary')[0].offsetHeight

        window.addEventListener('load', modScrollClass)

        window.addEventListener('scroll', _.throttle(modScrollClass, 500))

        function modScrollClass(e) {
            var scrollPosY = window.pageYOffset | document.body.scrollTop
            var navigationAdjusted = document.getElementsByClassName('headerPrimary')[0].offsetHeight
            if ( scrollPosY > navigationHeight ) {
                body.classList.add('mod--scrolled')
            } else if ( scrollPosY <= navigationHeight ) {
                body.classList.remove('mod--scrolled');
            }
        }

    }
}

export let navPrimary = new NavPrimary();
