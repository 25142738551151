import sal from 'sal.js'

// FILE: salJs.js

class SalJs {

    loadSalJs() {

        sal({
            rootMargin: '0% 50%',
            threshold: 0.2,
            once: true
        });

    }
}

export let salJs = new SalJs();

// ANIMATION OPTIONS
// fade
// slide-up
// slide-down
// slide-left
// slide-right
// zoom-in
// zoom-out
// flip-up
// flip-down
// flip-left
// flip-right
