// FILE: utility.js

class Utility {

    loadUtility() {

        if (objExists(document.getElementsByClassName('mod--100vh'))) {
            window.addEventListener('load', lockModuleHeight)
        }

        function lockModuleHeight(e) {
            var moduleObject = objExists(document.getElementsByClassName('moduleHalfHalf'))
            var moduleObjectInner = objExists(document.getElementsByClassName('moduleHalfHalf__wrapper'))
            var navHeight = document.getElementsByClassName('headerPrimary')[0].offsetHeight
            var windowHeight = window.outerHeight
            var outerObjectHeight = windowHeight - navHeight
            var innerObjectHeight = moduleObjectInner[0].offsetHeight
            moduleObject[0].style.height = `${outerObjectHeight}px`
            moduleObjectInner[0].style.height = `${innerObjectHeight}px`
            console.log(windowHeight)
            console.log(innerObjectHeight)
        }

        function objExists(obj) {
            if (obj.length > 0) {
                return obj
            } else {
                return false
            }
        }

    }
}

export let utility = new Utility();