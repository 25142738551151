import LazyLoad from 'vanilla-lazyload';

// FILE: lazyLoad.js

class Lazy {

    loadLazy() {

        var lazyLoad = new LazyLoad({
            elements_selector: ".lazy",
            // More options here
        })

        // console.log("Lazyload module loaded")

    }
}

export let lazy = new Lazy();
