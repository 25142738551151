// FILE: message.js

class Message {

    loadMessage() {

        const body = document.body

        const systemMessage = document.getElementById('systemMessage')

        const systemMessageTab = document.getElementById('systemMessage__tab')
        systemMessageTab.addEventListener('click', openSystemMessage)

        // const systemMessage = document.getElementById('systemMessage')

        function openSystemMessage() {
            systemMessage.classList.toggle('message--show')
            body.classList.toggle('message--active')
        }

        // console.log("Message module loaded")

    }
}

export let message = new Message();
